import {
  type OrderLine,
  type ProductVariant,
} from "@projectluna/codegen/schema";
import { getTranslation } from "@projectluna/lib/saleor/intl";

import {
  type CheckoutFragment,
  type CheckoutLineFragment,
  type ProductAvailabilityInfoFragment,
  type ProductVariantAvailabilityFragment,
} from "@/graphql/fragments/generated";
import type { Maybe } from "@/lib/types";

export const variantHash = ({ id }: { id: string }): string => id;

export const isVariantInStock = ({
  variantAvailability,
  checkoutLines,
  productAvailability,
}: {
  checkoutLines?: Maybe<CheckoutLineFragment[]>;
  productAvailability: Pick<ProductAvailabilityInfoFragment, "isAvailable">;
  variantAvailability: Maybe<ProductVariantAvailabilityFragment>;
}) => {
  if (!productAvailability.isAvailable) {
    return false;
  }

  return getVariantStock(variantAvailability, checkoutLines) > 0;
};

export const getVariantMaxQuantity = (
  variant: Maybe<
    Pick<ProductVariant, "quantityLimitPerCustomer" | "quantityAvailable">
  >
) => {
  if (variant?.quantityAvailable && variant?.quantityLimitPerCustomer) {
    const { quantityAvailable, quantityLimitPerCustomer } = variant;
    return quantityAvailable < quantityLimitPerCustomer
      ? quantityAvailable
      : quantityLimitPerCustomer;
  }

  return variant?.quantityLimitPerCustomer ?? variant?.quantityAvailable ?? 0;
};

type TranslatableName = {
  name: Maybe<string>;
  translation?: Maybe<{ name?: Maybe<string> }>;
};
export const getProductName = (
  product: TranslatableName,
  variant: TranslatableName
) => {
  const variantName = getTranslation("name", variant).trim();
  const productName = getTranslation("name", product).trim();

  if (variantName) {
    return `${productName} • ${variantName}`;
  }
  return productName;
};

export const getLineProductName = (
  line: Pick<
    OrderLine,
    | "productName"
    | "variantName"
    | "translatedVariantName"
    | "translatedProductName"
  >
) => {
  /**
   * TODO
   * Saleor sets it to: variantName: 'ID:16' when empty, regexp?
   * const variantName = (line.translatedVariantName || line.variantName).trim();
   */
  const variantName = "";
  const productName = (line.translatedProductName || line.productName).trim();

  if (variantName) {
    return `${productName} • ${variantName}`;
  }
  return productName;
};

export const getVariantStock = (
  variant: Maybe<ProductVariantAvailabilityFragment>,
  checkoutLines: Maybe<CheckoutFragment["lines"]>
) => {
  if (!variant) {
    return 0;
  }

  const { id, quantityAvailable, quantityLimitPerCustomer } = variant;
  const checkoutQuantity = (
    checkoutLines?.filter(line => line.variant.id === id) ?? []
  )
    .map(line => line.quantity)
    .reduce((acc, val) => acc + val, 0);

  if (quantityAvailable) {
    if (quantityLimitPerCustomer) {
      const quantity = getVariantMaxQuantity(variant) - checkoutQuantity;
      return quantity > 0 ? quantity : 0;
    }

    return quantityAvailable - checkoutQuantity;
  }

  return 0;
};
