import type {
  ProductVariant,
  ShippingMethod,
} from "@projectluna/codegen/schema";

import { CLIENT_CONFIG } from "@/config/client";
import {
  type CheckoutBaseFragment,
  type CheckoutFragment,
  type CheckoutLineFragment,
  type OrderDetailsFragment,
  type ProductAvailabilityInfoFragment,
  type ProductBasicDetailsFragment,
} from "@/graphql/fragments/generated";
import type {
  ProductDetailsQuery,
  ProductDetailsQuery_product_Product,
} from "@/graphql/queries/generated";
import type { PaymentMethodType } from "@/lib/payment/types";
import { getPrice } from "@/lib/price";
import {
  getLineProductName,
  getVariantMaxQuantity,
} from "@/lib/product/helpers";

import { sendGAEvent, sendGTMEvent } from "./google/client";

const serializeLine = ({
  variant: { product, ...variant },
  unitPrice,
  quantity,
}: CheckoutLineFragment) => {
  const price = getPrice(unitPrice, product.pricing?.displayGrossPrices);
  return {
    item_id: variant.id,
    item_name: product.name,
    item_variant: variant.name,
    item_category: product.category?.name,
    price: price?.amount,
    quantity,
  };
};

const calculateLinesPrice = (lines: CheckoutFragment["lines"]) => {
  const currency = lines[0].unitPrice.gross.currency;
  const value = lines
    .map(
      ({ unitPrice, variant, quantity }) =>
        (getPrice(unitPrice, variant?.product?.pricing?.displayGrossPrices)
          ?.amount ?? 0) * quantity
    )
    .reduce((accumulator, currentValue) => accumulator! + currentValue!, 0)!;
  return { currency, value };
};

const extractPrice = (checkout: CheckoutFragment) => ({
  value: checkout.displayGrossPrices
    ? checkout.totalPrice.gross.amount
    : checkout.totalPrice.net.amount,
  currency: checkout.totalPrice.net.currency,
});

const trackAddToCart = (lines: NonNullable<CheckoutLineFragment>[]) => {
  sendGAEvent({
    action: "event",
    event: "add_to_cart",
    data: {
      ...calculateLinesPrice(lines),
      items: lines.map(serializeLine),
    },
  });
};

const trackRemoveFromCart = (lines: NonNullable<CheckoutLineFragment>[]) => {
  sendGAEvent({
    action: "event",
    event: "remove_from_cart",
    data: {
      ...calculateLinesPrice(lines),
      items: lines.map(serializeLine),
    },
  });
};

const trackViewProduct = (
  product: ProductDetailsQuery_product_Product,
  productAvailability: NonNullable<ProductAvailabilityInfoFragment>,
  variant: NonNullable<
    NonNullable<ProductDetailsQuery["product"]>["variants"]
  >[number]
) => {
  const variantPricing = productAvailability.variantsAvailability?.find(
    ({ id }) => variant.id === id
  )?.pricing;
  const price = getPrice(
    variantPricing?.price,
    product.pricing?.displayGrossPrices
  );

  sendGAEvent({
    event: "view_item",
    action: "event",
    data: {
      value: price?.amount,
      currency: price?.currency,
      items: [
        {
          item_id: variant.id,
          item_name: product.name,
          item_variant: variant.name,
          item_category: product.category?.name,
          price: price?.amount,
          quantity: getVariantMaxQuantity(variant),
        },
      ],
    },
  });
};

const trackViewProductFromListing = (
  name: string,
  product: ProductBasicDetailsFragment,
  variants: Pick<ProductVariant, "id" | "name">[]
) => {
  sendGAEvent({
    event: "view_item_list",
    action: "event",
    data: {
      item_list_name: name,
      items: variants.map(({ id, name }) => ({
        item_id: id,
        item_name: product.name,
        item_variant: name,
      })),
    },
  });
};

const trackViewCart = (checkout: CheckoutBaseFragment) => {
  const lines = checkout?.lines;

  if (lines?.length) {
    sendGAEvent({
      event: "view_cart",
      action: "event",
      data: {
        ...calculateLinesPrice(lines),
        items: lines.map(serializeLine),
      },
    });
  }
};

const trackCheckoutAddPaymentInfo = (
  checkout: CheckoutFragment,
  paymentType: PaymentMethodType
) => {
  sendGAEvent({
    event: "add_payment_info",
    action: "event",
    data: {
      ...extractPrice(checkout),
      payment_type: paymentType,
      items: checkout.lines.map(serializeLine),
    },
  });
};

const trackCheckoutAddShippingInfo = (
  checkout: CheckoutFragment,
  shippingName: string
) => {
  sendGAEvent({
    event: "add_payment_info",
    action: "event",
    data: {
      ...extractPrice(checkout),
      shipping_tier: shippingName,
      items: checkout.lines.map(serializeLine),
    },
  });
};

const trackCheckoutBegin = (checkout: CheckoutFragment) => {
  sendGAEvent({
    event: "begin_checkout",
    action: "event",
    data: {
      ...extractPrice(checkout),
      items: checkout.lines.map(serializeLine),
    },
  });
};

const trackOrderPlaced = ({
  total,
  deliveryMethod,
  collectionPointName,
  lines,
}: OrderDetailsFragment) => {
  const value = total.gross.amount;
  const currency = total.gross.currency;
  const isSelfPickup = !!collectionPointName;

  sendGAEvent({
    event: "purchase",
    action: "event",
    data: {
      value,
      currency,
      shipping: isSelfPickup
        ? 0
        : (deliveryMethod as unknown as ShippingMethod).price.amount,
      items: lines.map(({ quantity, unitPrice, variant, ...line }) => ({
        quantity,
        item_id: variant?.product?.id,
        item_name: getLineProductName(line),
        item_variant: variant?.id,
        price: unitPrice.gross.amount,
      })),
    },
  });

  sendGTMEvent({
    event: "conversion",
    send_to: `${CLIENT_CONFIG.GTM_ID}/kzgTCP6ViqIZEPLLqcQ9`,
    value,
    currency,
    transaction_id: "",
  });
};

const trackSearch = ({ searchTerm }: { searchTerm: string }) => {
  sendGAEvent({
    event: "search",
    action: "event",
    data: {
      search_term: searchTerm,
    },
  });
};

export const trackActivity = {
  addToCart: trackAddToCart,
  removeFromCart: trackRemoveFromCart,
  viewProduct: trackViewProduct,
  viewCart: trackViewCart,
  viewProductFromListing: trackViewProductFromListing,
  checkoutAddPaymentInfo: trackCheckoutAddPaymentInfo,
  checkoutAddShippingInfo: trackCheckoutAddShippingInfo,
  checkoutBegin: trackCheckoutBegin,
  orderPlaced: trackOrderPlaced,
  search: trackSearch,
};
