import { type Maybe } from "#types";

export const getTranslation = <T, K extends keyof T>(
  key: K,
  type: Maybe<
    /* eslint-disable */
    {
      [key in K]?: Maybe<string>;
    } & { translation?: Maybe<{ [key in K]?: Maybe<string> }> }
    /* eslint-enable */
  >
) => (type?.translation?.[key] || type?.[key] || "")?.trim();
