import { type TaxedMoneyFragment } from "@projectluna/codegen/graphql/fragments/generated";

import { type Maybe, type MaybeWithoutTypename } from "@/lib/types";

const getPrice = (
  price: MaybeWithoutTypename<TaxedMoneyFragment>,
  displayGrossPrices: Maybe<boolean>
) => (!displayGrossPrices ? price?.net : price?.gross);

const getUndiscountedPrice = (
  priceUndiscounted: MaybeWithoutTypename<TaxedMoneyFragment>,
  displayGrossPrices: Maybe<boolean>
) => (!displayGrossPrices ? priceUndiscounted?.net : priceUndiscounted?.gross);

export { getPrice, getUndiscountedPrice };
